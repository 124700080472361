@font-face {
  font-family: 'Noto Sans KR Light';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/NotoSansKR-Light.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NotoSansKR-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/NotoSansKR-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/NotoSansKR-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR Thin';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NotoSansKR-Thin.woff') format('woff');
}